<template>
  <div
    v-if="onboardingProgression < 100"
    class="flex flex-wrap gap-y-4 items-center px-6 py-2 rounded-sm w-full"
    style="background-color: #f3f6ff"
  >
    <div class="inline-block mr-3 relative">
      <svg width="54" height="54" class="relative">
        <circle
          cx="27"
          cy="27"
          r="25"
          stroke-width="4"
          fill="none"
          stroke="#E3E9FE"
        ></circle>
        <circle
          cx="27"
          cy="27"
          r="25"
          stroke-width="4"
          fill="none"
          stroke="#274FED"
          stroke-linecap="round"
          :stroke-dasharray="strokeDashArray"
          :stroke-dashoffset="strokeDashOffset"
        ></circle>
      </svg>
      <div
        class="absolute left-1/2 top-1/2 font-bold"
        style="transform: translate(-50%, -50%); margin-top: 2px"
      >
        {{ onboardingProgression }}%
      </div>
    </div>
    <div class="mr-3 text-xs">
      Your personal information is incomplete.
      <br>
      Update your profile.
    </div>
    <button
      class="btn btn-blue btn-sm mb-2 mx-auto py-3 whitespace-no-wrap"
      @click="update"
    >
      Update Profile
    </button>
  </div>
</template>

<script>
export default {
  name: "Onboarding",
  data() {
    return {};
  },
  computed: {
    onboardingProgression() {
      const requirements = [
        'bvn',
        'onboarding.account_verification',
        'nin',
        'onboarding.form2',
        'work_id',
      ];

      const point = Math.round(100 / requirements.length);

      return requirements.reduce((progression, requirement) => {
        return progression + (this.validateUser(requirement) ? point : 0);
      }, 0);
    },
    strokeDashArray() {
      return 156;
    },
    strokeDashOffset() {
      return (
        this.strokeDashArray -
        (this.onboardingProgression / 100) * this.strokeDashArray
      );
    },
  },
  methods: {
    update() {
      if (this.validateUser('approval')) {
        return this.$router.push({ name: 'settings-profile' });
      }

      return this.resumeOnboarding();
    },
  },
};
</script>
